export const StakeHolderMenuItem: INavigationItem = {
  id: "stakeholders-0",
  label: "Stakeholders",
  icon: "Profile2User",
  path: "/",
  children: [],
};

export const AccountsMenuItem: INavigationItem = {
  id: "accounts-0", // Savings Credit Investments Assets
  label: "Accounts",
  icon: "Bank",
  path: "/",
  children: [],
};

export const ReportsMenuItem: INavigationItem = {
  id: "report-0",
  label: "Reports",
  icon: "ArchiveBook",
  path: "/",
  children: [],
};

export const DocumentsMenuItem: INavigationItem = {
  id: "documents-0",
  label: "Documents",
  icon: "DocumentText",
  path: "/",
  children: [],
};


export const AutomationsMenuItem: INavigationItem = {
  id: "automation-0", //processes & decisions
  label: "Automations",
  icon: "Code1",
  path: "/",
  children: [],
};

export const WorkflowsMenuItem: INavigationItem = {
  id: "workflows-0", //tasklist and forms
  label: "Workflows",
  icon: "Layer",
  path: "/",
  children: [],
};

export const LoggerItem: INavigationItem = {
  id: "logger-0",
  label: "Log Analyzer",
  icon: "KeyboardOpen",
  path: "/",
  children: [],
};

export const CollectionsItem: INavigationItem = {
  id: "collections-0",
  label: "Collections",
  icon: "MoneyRecive",
  path: "/",
  children: [],
};

export const InvoicesItem: INavigationItem = {
  id: "invoices-0",
  label: "Invoices",
  icon: "ShoppingCart",
  path: "/",
  children: [],
};

export const BillersItem: INavigationItem = {
  id: "billers-0",
  label: "Billers",
  icon: "Bill",
  path: "/",
  children: [],
};

export const SettlementsItem: INavigationItem = {
  id: "settlements-0",
  label: "Settlements",
  icon: "ClipboardTick",
  path: "/",
  children: [],
};

export const LedgersItem: INavigationItem = {
  id: "ledgers-0",
  label: "Ledgers",
  icon: "FolderOpen",
  path: "/",
  children: [],
};

export const ScheduledJobsItem: INavigationItem = {
  id: "cron-jobs-0",
  label: "Scheduled Jobs",
  icon: "TimerStart",
  path: "/",
  children: [],
};

export const QueueMonitorsItem: INavigationItem = {
  id: "monitors-0",
  label: "Queue Monitor",
  icon: "Microscope",
  path: "/",
  children: [],
};