/**
 * Replace the AppLibrary call with a call to a rest API
 * This should read off Odoo Products (for ADIBA) company
 */
export const  AppLibrary: IApplicationItem[] = [
  {
    seq: 10,
    id: "retail-engine",
    title: "Retail / Loan Engine",
    icon: "WalletMoney",
    description: "Admin console for retail banking operations.",
  },
  {
    seq: 20,
    id: "process-engine",
    title: "Process Manager",
    icon: "HierarchySquare2",
    description: "Manage compliance and other processes.",
  },
  {
    seq: 30,
    id: "payment-engine",
    title: "Payments Engine",
    icon: "EmptyWallet",
    description: "Track and reconcile all your payments.",
  },
  {
    seq: 40,
    id: "ledger-sync",
    title: "Ledger Sync Portal",
    icon: "ConvertCard",
    description: "Guarantee synchronized records across ledgers.",
  },
];
