import {
  Box,
  Group,
  Card,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  Title,
  ActionIcon,
  Space,
  Button,
} from "@mantine/core";
import {
  IconBrandMastercard,
  IconBrandVisa,
  IconCircle,
  IconCircleCheck,
} from "@tabler/icons-react";
import {  Add } from "iconsax-react";
import React from "react";

export const SubscriptionsSettingsPaymentMethodFeature = () => {
  const PaymentMethods = [
    {
      type: "Mastercard",
      logo: <IconBrandMastercard size={80} strokeWidth={0.4} />,
      pan: "6890",
      exp: "12/23",
      active: true,
    },
    {
      type: "Visa",
      logo: <IconBrandVisa size={80} strokeWidth={0.4} />,
      pan: "6890",
      exp: "12/23",
      active: false,
    },
  ];
  const PaymentMethodItems = PaymentMethods.map((item, index) => {
    return (
      <Card
        withBorder
        radius="md"
        p={0}
        key={index}
        bg={item.active ? "gray.1" : "white"}
      >
        <Group gap={"md"} px={"sm"}>
          <Box>{item.logo}</Box>
          <Stack gap={3}>
            <Text fz={14} tt="uppercase" fw={500}>
              {`**** **** **** ${item.pan}`}
            </Text>
            <Group c={"dimmed"}>
              <Text fw={300} size="xs">
                {item.type}
              </Text>
              <Text fw={300} size="sm">
                {item.exp}
              </Text>
            </Group>
          </Stack>
          <Space flex={1} />

          <ActionIcon variant="subtle" disabled={item.active}>
            {!item.active ? <IconCircle /> : <IconCircleCheck />}
          </ActionIcon>
        </Group>
      </Card>
    );
  });
  PaymentMethodItems.push(
    <Group align="center" key={PaymentMethodItems.length}>
      <Button color={"adiba"} variant="outline" w={60} h={60}>
        <Add size="60" strokeWidth={1} />
      </Button>
    </Group>
  );
  return (
    <Stack gap={'sm'}>
      <Title fz={"sm"} fw={500} pt={"md"} c={"dimmed"}>
      Payment Methods
      </Title>
      <ScrollArea scrollbars="x">
        <SimpleGrid cols={3} w={1024} py={"sm"} spacing={"md"}>
          {PaymentMethodItems}
        </SimpleGrid>
      </ScrollArea>
    </Stack>
  );
};
