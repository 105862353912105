import * as React from "react";
import { UnstyledButton } from "@mantine/core";
import "./style.css";
import { RedirectLink } from "../../library/navigate/link";

export const DefaultLogoComponent = () => {
  return (
    <UnstyledButton
      className="ics-logo-component"
      onClick={RedirectLink('/')}
      mih={60}
    />
  );
};
