import {
  Group,
  Card,
  Stack,
  Title,
  Text,
  ActionIcon,
  ColorSwatch,
  Space,
  Divider,
  Menu,
} from "@mantine/core";
import {
  ArrowRight2,
  Driver,
  More,
  PauseCircle,
} from "iconsax-react";
import React from "react";

export const ConnectionListItemShell = () => {
  return (
    <>
      <Card withBorder bg={"gray.0"} p="lg" radius="md" color="red">
        <Stack>
          <Group align="flex-start">
            <Driver
              className="ics-path-override"
              style={{ strokeWidth: 0.5 }}
              size={48}
            />
            <Stack gap={5}>
              <Title fw={400} order={6}>
                Identity Server
              </Title>
              <Group justify="flex-start" gap={5}>
                <ColorSwatch size={12} color="lime" />
                <Text fw={300} fz={"xs"}>
                  Active
                </Text>
              </Group>
            </Stack>
            <Space flex={1} />
            <Menu
              width={190}
              transitionProps={{ transition: "pop" }}
              position="bottom"
              withArrow
              withinPortal
              arrowSize={20}
            >
              <Menu.Target>
                <ActionIcon variant="subtle" m={0}>
                  <More className="ics-path-override" size={16} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  h={40}
                  onClick={void 0}
                  rightSection={
                    <ArrowRight2 className="ics-path-override" size={16} />
                  }
                >
                  <Text size="xs" fw={300}>
                    {/* {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.createticket} */}
                    Edit Connections
                  </Text>
                </Menu.Item>
                <Menu.Item
                  h={40}
                  onClick={void 0}
                  rightSection={
                    <ArrowRight2 className="ics-path-override" size={16} />
                  }
                >
                  <Text size="xs" fw={300}>
                    {/* {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.createticket} */}
                    Test Connections
                  </Text>
                </Menu.Item>
                <Divider />
                <Menu.Item
                  h={40}
                  onClick={void 0}
                  c={"red"}
                  leftSection={
                    <PauseCircle
                      className="ics-path-override"
                      size={16}
                      color="red"
                    />
                  }
                >
                  <Text size="xs" fw={300}>
                    {/* {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.createticket} */}
                    Pause Connections
                  </Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
          {ConnectionDetailsShell()}
        </Stack>
      </Card>
    </>
  );
};

const ConnectionDetailsShell = () => {
  const ConnectionDetails = [
    {
      label: "Server:",
      value: "identity.apps.sandbox.srv",
    },
    {
      label: "Database:",
      value: "identity.apps.sandbox.srv",
    },
    {
      label: "Username:",
      value: "identity.apps.sandbox.srv",
    },
    {
      label: "Password:",
      value: "**hidden**",
    },
  ].map((item, index) => {
    return (
      <Group key={index}>
        <Text w={100} fw={300} fz={"sm"}>
          {item.label}
        </Text>
        <Text fw={300} fz={"sm"} c={"altiba"}>
          {item.value}
        </Text>
      </Group>
    );
  });
  return (
    <Card.Section style={{ borderTop: "1px solid #ddd" }}>
      <Stack mt={0} bg={"white"} p={"md"} gap={"lg"} py={"lg"}>
        {ConnectionDetails}
      </Stack>
    </Card.Section>
  );
};

// lime => active, red => disconnected , gold = connecting , lightgray = unknown
