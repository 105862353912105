import { Button, Divider, Group, Popover, Stack, Text } from "@mantine/core";
import React from "react";
import { ApplicationSwitcherItemUIShell } from "../../../entities/applications/ui/switcher";
import { NAVIGATION_CONSTANT } from "../../../../constants";
import { Link } from "react-router-dom";

interface IApplicationItem {
  seq: number;
  id: string | number;
  title: string;
  icon: string;
  description: string;
}

interface IApplicationItemProps {
  ApplicationPreviewData: IApplicationItem[],
  toggle: () => void
}

export const ApplicationsPreviewFeature = (
  {ApplicationPreviewData, toggle} :IApplicationItemProps
) => {
  return (
    <>
      <Popover.Dropdown ml="md" p={5}>
        <Stack gap={5} p={0}>
          <Stack gap={5} align="flex-start">
            {Object.keys(ApplicationPreviewData).map((key, index) => (
              <ApplicationSwitcherItemUIShell
                key={`application-switcher-item-${index}`}
                {...ApplicationPreviewData[key]}
              />
            ))}
          </Stack>
          <Divider />
          <Group grow justify="center">
            <Button variant="subtle" p={0} component={Link} to={"/settings/applications?q=myapplications"} onClick={toggle}>
              <Text size="xs" fw={300} w={320}>
                {NAVIGATION_CONSTANT.MORE_TEXT.APPLICATIONS} &raquo;
              </Text>
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </>
  );
};
