import {
  setActiveApp,
  setContextApps,
} from "./entities/applications/actions/applications";
import { createOidcApi } from "piral-oidc";
import {
  setMenu,
  setActiveMenu,
  registerMenuItemApi,
} from "./widgets/navigation/actions/sidemenu";
import { createStandardApi } from "piral";
import { createInstance, Piral } from "piral-core";
import React from "react";
import { createRoot } from "react-dom/client";
import { errors } from "./pages/errors";
import { layout } from "./app/ui/_BaseLayout";

import { setDrawer, setPageMeta } from "./pages/layouts";
import { SettingsHomePage } from "./pages/core/settings/ui/home";
import { registerPiralActionApi } from "./app/actions/api";
import { client } from "../auth.config";

export const render = (meta) => {
  const FEED_URL = process.env.FEED_URL || "";
  const instance = createInstance({
    actions: {
      setMenu: setMenu,
      setActiveMenu: setActiveMenu,
      setActiveApp: setActiveApp,
      setContextApps: setContextApps,
      setDrawer: setDrawer,
      setPageMeta: setPageMeta,
    },
    state: {
      ...meta,
      components: layout,
      errorComponents: errors,
      drawer: {},
    },
    plugins: [
      ...createStandardApi(),
      registerMenuItemApi(),
      registerPiralActionApi(),
      createOidcApi(client),
    ],
    requestPilets() {
      return fetch(FEED_URL)
      .then((res) => {
        if (res.ok) {
          return res.json().then((res) => res.items);
        } else {
          console.error(`Error fetching pilets: ${res.status} ${res.statusText}`); // Log the error
          return [];
        }
      })
      .catch((error) => {
        console.error(`Error fetching pilets: ${error}`); 
        return [];
      });
    },
  });

  const root = createRoot(document.querySelector("#app"));
  root.render(<Piral instance={instance} />);

  instance.root.registerPage("/settings/:category", SettingsHomePage);
  instance.context.emit("abc-menu-loaded", {});
};
