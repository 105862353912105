import { Group, Text } from "@mantine/core";
import { NAVIGATION_CONSTANT } from "../../../../constants";
import React from "react";

export const SubscriptionsCheckFeature = () => {
    return <>
    <Group justify="space-between">
        <Text size="sm" fw={300}>{NAVIGATION_CONSTANT.SIDENAV.PLAN}</Text>
        <Text size="sm" fw={500}>Gold</Text>
    </Group>
    </>;
}