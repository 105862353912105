import { ConnectionListItemShell } from "../../../entities/connections/ui/list/shell";
import { SimpleGrid } from "@mantine/core";
import React from "react";

export const ConnectionsSettingsWidget = () => {
  const Connections = Array(6)
    .fill(0)
    .map((item, index) => {
      return <ConnectionListItemShell key={index} />;
    });
  return (
    <SimpleGrid cols={3} pt={"md"}>
      {Connections}
    </SimpleGrid>
  );
};
