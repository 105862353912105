import { NotificationsPreviewFeature } from "../../../../features/notifications/preview";
import { ActionIcon, Popover } from "@mantine/core";
import { NotificaitonPreviewData } from "../../model/notification";
import React from "react";
import { IconBell } from "@tabler/icons-react";

export const NotificationMenuTrigger = () => {
  return (
    <Popover
        width={300}
        transitionProps={{ transition: "pop" }}
        position="bottom"
        withArrow
        withinPortal
        offset={10}
        arrowOffset={20}
        arrowSize={10}>
      <Popover.Target>
        <ActionIcon variant="subtle" h={40} w={40}>
          <IconBell stroke={1} />
        </ActionIcon>
      </Popover.Target>
      <NotificationsPreviewFeature {...NotificaitonPreviewData} />
    </Popover>
  );
};
