import { Divider, Stack } from "@mantine/core"
import { SubscriptionsSettingsBillingHistoryFeature } from "../../../features/subscriptions/settings/bills"
import { SubscriptionsSettingsPaymentMethodFeature } from "../../../features/subscriptions/settings/payments"
import { SubscriptionsSettingsPlanFeature } from "../../../features/subscriptions/settings/plan"
import React from "react"

export const SubscriptionsSettingsWidget = ()=>{
    return <Stack gap={'xl'}>
        <SubscriptionsSettingsPlanFeature/>
        <SubscriptionsSettingsPaymentMethodFeature/>
        <SubscriptionsSettingsBillingHistoryFeature/>
    </Stack>
}