import React from "react";

export const ProcessEngineDashboardHomePage = ({ children }) => {
  return (
    <>
      <>ProcessEngineDashboardHomePage</>
      {children}
    </>
  );
};
