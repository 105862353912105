import {
  StakeHolderMenuItem,
  AccountsMenuItem,
  ReportsMenuItem,
  AutomationsMenuItem,
  WorkflowsMenuItem,
  LoggerItem,
  BillersItem,
  InvoicesItem,
  CollectionsItem,
  SettlementsItem,
  LedgersItem,
  ScheduledJobsItem,
  QueueMonitorsItem,
} from "../../../../entities/applications/model/menuitems";

export const SetApplicationSideMenu = (applications, setMenu) => {
  const applicationSet = new Set(applications);

  if (applicationSet.has("retail-engine")) {
    /* RETAIL ENGINE DEFAULT MENU */
    setMenu("retail-engine", [
      StakeHolderMenuItem,
      AccountsMenuItem,
      ReportsMenuItem,
    ]);
  }

  /* PROCESS ENGINE DEFAULT MENU */
  if (applicationSet.has("process-engine")) {
    setMenu("process-engine", [
      AutomationsMenuItem,
      WorkflowsMenuItem,
      LoggerItem,
    ]);
  }

  /* PAYMENTS ENGINE DEFAULT MENU */
  if (applicationSet.has("payment-engine")) {
    setMenu("payment-engine", [
      BillersItem,
      InvoicesItem,
      CollectionsItem,
      SettlementsItem,
    ]);
  }

  /* LEDGER SYNC DEFAULT MENU */
  if (applicationSet.has("ledger-sync")) {
    setMenu("ledger-sync", [
      LedgersItem,
      ScheduledJobsItem,
      QueueMonitorsItem,
    ]);
  }
};
