import React from "react";
import { Card, Tabs } from "@mantine/core";
import { useAction } from "piral-core";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ApplicationsSettingsWidget } from "../../../../widgets/settings/ui/applications";
import { PreferencesSettingsWidget } from "../../../../widgets/settings/ui/preferences";
import { ConnectionsSettingsWidget } from "../../../../widgets/settings/ui/connections";
import { SubscriptionsSettingsWidget } from "../../../../widgets/settings/ui/subscriptions";
import { RedirectLink } from "../../../../shared/library/navigate/link";

export const SettingsHomePage = () => {
  const location = useLocation();
  const SettingsCategory = useParams();
  const setPageMeta = useAction("setPageMeta");
  setPageMeta("Settings", "Capitalfield Financial Management Limited");

  const TabItems = [
    ["Preferences", "preferences"],
    ["Connections", "connections"],
    ["Subscriptions", "subscriptions"],
    ["Applications", "applications"],
  ];

  const TabsList = TabItems.map((item, index) => {
    const targetPath = `/settings/${item[1]}`;
    return (
      <Tabs.Tab
        p={"md"}
        fw={300}
        value={item[1]}
        key={index}
        onClick={RedirectLink(targetPath)}
      >
        {item[0]}
      </Tabs.Tab>
    );
  });

  const TabsPanels = {
    applications: <ApplicationsSettingsWidget />,
    preferences: <PreferencesSettingsWidget />,
    connections: <ConnectionsSettingsWidget />,
    subscriptions: <SubscriptionsSettingsWidget />,
  };

  return (
    <Card flex={1} bg={"white"} radius={"md"}>
      <Tabs value={location.pathname.split("/")[2]}>
        <Tabs.List>{TabsList}</Tabs.List>
        {TabsPanels[SettingsCategory?.category]}
      </Tabs>
    </Card>
  );
};
