export const CompanyData = [{
    label: "Company Name:",
    value: "Capitafield Financial Management Limited"
},
{
    label: "Tenant Identifier:",
    value: "c9a6ba27-27c3-4a0c-b511-b15a5743ed41"
},
{
    label: "Job Designation:",
    value: "Systems Administrator"
},
{
    label: "Company Email:",
    value: "info@capitalfieldfml.com"
}]