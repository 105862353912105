import {
  Flex,
  Paper,
  ThemeIcon,
  Stack,
  Title,
  Button,
  Group,
  Text,
  Card,
  Badge,
  Image,
  rem,
  SimpleGrid,
} from "@mantine/core";
import {
  IconArrowRight,
  IconGasStation,
  IconGauge,
  IconManualGearbox,
  IconUsers,
} from "@tabler/icons-react";
import React from "react";

export default function ApplicationCard({ info, color }) {
  return (
    <Flex direction="column">
      <Paper
        w="fit-content"
        shadow="sm"
        p={15}
        radius="full"
        pos="relative"
        top={32}
        left={28}
        className="z-10"
      >
        <ThemeIcon radius="full" className="rounded-full" size={50} bg={color}>
          {info.symbol}
        </ThemeIcon>
      </Paper>
      <Card
        withBorder
        miw={250}
        h={400}
        bg="#F9F9FB"
        p={28}
        radius={20}
        // className="border-[#D5DBE1] flex flex-col justify-between"
      >
        <Stack mt={28} gap={12}>
          <Title order={5} c={"primaryBlue.8"}>
            {info.title}
          </Title>
          <Text c={"primaryBlue.6"} fw={300} fz={16}>
            {info.description}
          </Text>
        </Stack>
        <Stack>
          <Button
            variant="filled"
            color="#E2C8AA"
            c={"primaryBlue.8"}
            fz={16}
            fw={300}
            justify="center"
            rightSection={<IconArrowRight size={24} />}
            radius="xl"
            size="md"
            w={198}
          >
            Read More
          </Button>
          <Group mt={24}>
            <Text c={"primaryBlue.8"} fw={300} fz={'sm'}>
              Subscription
            </Text>
            <Text py={8} c={"primaryBlue.8"} fw={700} fz={28}>
              ${info.subscription_fee}
              <Text span c={"primaryBlue.1"} fw={500} fz={14}>
                /month
              </Text>
            </Text>
          </Group>
        </Stack>
      </Card>
    </Flex>
  );
}

const mockdata = [
  { label: "4 passengers", icon: IconUsers },
  { label: "100 km/h in 4 seconds", icon: IconGauge },
  // { label: "Automatic gearbox", icon: IconManualGearbox },
  // { label: "Electric", icon: IconGasStation },
];

export function FeaturesCard() {
  const features = mockdata.map((feature) => (
    <Group key={feature.label} gap={'xs'}>
      <feature.icon size={16} color={"gray"} stroke={1.5} />
      <Text size="xs" w={100} truncate>{feature.label}</Text>
    </Group>
  ));

  return (
    <Card withBorder radius="md">
      <Card.Section>
      <Image src="https://placehold.co/600x400" alt="Tesla Model S" />
      </Card.Section>
      <Card.Section p={"md"}>
        <Stack gap={"sm"}>
        <Group justify="space-between">
          <Text fw={400}>Tesla Model S</Text>
          <Badge fw={300} variant="outline">
            25% off
          </Badge>
        </Group>
        <Text fw={300} fz="sm" c="adiba.5">
          Tool for ensuring compliance with financial regulations, including
          Basel III, IFRS, and FATCA.
        </Text>
      </Stack>
      </Card.Section>
      <Card.Section p={"md"} pt={0}>
        <Text
          fz="xs"
          c="gray.5"
          mb={"xs"}
          lh={1}
          fw={500}
          lts={rem("-0.25px")}
          tt={"uppercase"}
        >
          Basic configuration
        </Text>

        <SimpleGrid cols={1} spacing={'xs'} verticalSpacing={'xs'}>
          {features}
        </SimpleGrid>
      </Card.Section>

      <Card.Section p={"md"}>
        <Group gap={30} justify="space-between">
          <div>
            <Text fz="lg" fw={500} style={{ lineHeight: 1 }}>
              $168.00
            </Text>
            <Text fz="xs" c="dimmed" fw={300} style={{ lineHeight: 1 }} mt={3}>
              per day
            </Text>
          </div>

          <Button color="adiba" variant="filled" fw={300} radius="md" >
            Add to Plan
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
}
