import { Profile2User, Bank, ArchiveBook, Notepad2, DocumentText, ConvertCard, EmptyWallet, ProgrammingArrow, WalletMoney, HierarchySquare2, Layer, KeyboardOpen, Code1, ShoppingCart, Bill, MoneyRecive, ClipboardTick, FolderOpen, TimerStart, Microscope } from "iconsax-react";
import React from "react";

export const SideMenuIcons = {
  Profile2User: <Profile2User size={16} className="ics-path-override" />,
  Bank: <Bank size={16} className="ics-path-override" />,
  ArchiveBook: <ArchiveBook size={16} className="ics-path-override" />,
  Notepad2: <Notepad2 size={16} className="ics-path-override" />,
  DocumentText: <DocumentText size={16} className="ics-path-override" />,
  ProgrammingArrow: <ProgrammingArrow size={16} className="ics-path-override" />,
  Layer: <Layer size={16} className="ics-path-override" />,
  KeyboardOpen: <KeyboardOpen size={16} className="ics-path-override" />,
  Code1: <Code1 size={16} className="ics-path-override" />,
  Bill: <Bill size={16} className="ics-path-override" />,
  ShoppingCart: <ShoppingCart size={16} className="ics-path-override" />,
  MoneyRecive: <MoneyRecive size={16} className="ics-path-override" />,
  ClipboardTick: <ClipboardTick size={16} className="ics-path-override" />,
  FolderOpen: <FolderOpen size={16} className="ics-path-override" />,
  TimerStart: <TimerStart size={16} className="ics-path-override" />,
  Microscope: <Microscope size={16} className="ics-path-override" />,
};


export const ApplicationIcons = {
  WalletMoney: <WalletMoney width={40} size={24} className="ics-path-override" />,
  HierarchySquare2: <HierarchySquare2  width={40} size={24}  className="ics-path-override" />,
  EmptyWallet: <EmptyWallet  width={40} size={24}  className="ics-path-override" />,
  ConvertCard: <ConvertCard  width={40} size={24}  className="ics-path-override" />,
}