import { GlobalStateContext } from "piral-core";

declare module "piral-core/lib/types/custom" {
  interface PiralCustomActions {
    setDrawer: (page?: React.JSX.Element, title? : string) => void;
  }

  interface PiralCustomState {
    drawer: {
      open: boolean;
      page: React.JSX.Element | undefined;
      title?: string
    }
  }
}

export const setDrawer = (
  ctx: GlobalStateContext,
  page: React.JSX.Element = undefined,
  title: string = ""
) => {
  ctx.dispatch((state) => ({
    ...state,
    drawer: {
      open: (page != undefined),
      page: (page != undefined) ? page : undefined,
      title: title
    }
  }));
};