import { TextInput, TextInputProps, ActionIcon, useMantineTheme, rem } from '@mantine/core';
import { IconSearch, IconArrowRight } from '@tabler/icons-react';
import React from 'react';

export function InputWithButton(props: TextInputProps) {
  const theme = useMantineTheme();

  return (
    <TextInput
      radius="xl"
      size="md"
      placeholder="Search questions"
      rightSectionWidth={42}
      leftSection={<IconSearch style={{ width: rem(24), height: rem(24) }} stroke={1.5} />}
      rightSection={
        <ActionIcon size='lg' radius="xl" color={theme.primaryColor} variant="filled">
          <IconArrowRight style={{ width: rem(24), height: rem(24) }} stroke={1.5} />
        </ActionIcon>
      }
      {...props}
    />
  );
}