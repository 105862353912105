import { Button, Divider, Group, Popover } from "@mantine/core";
import { ArrowDown2 } from "iconsax-react";
import React from "react";

interface disclosureProps {
    opened: boolean;
    toggle?: () => void;
    application : string
  }
  
  export const ApplicationsDefaultFeature = ({ opened, toggle, application }: disclosureProps ) => {
  return (
    <Popover.Target>
        <Group gap={0} w={200} justify="space-between">
        <Button
            variant="transparent"
            h={60}
            fw={300}
            rightSection={<ArrowDown2 size={16} />}
            onClick={toggle}
        >
            { application }
        </Button>
        <Divider orientation="vertical" visibleFrom="md"/>
        </Group>
    </Popover.Target>
  );
};
