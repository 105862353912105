import { Stack, Space, Card, Burger } from "@mantine/core";
import React from "react";
import { ApplicationsSandboxFeature } from "../../../features/applications/sandbox";
import { ConnectionsReferrerIPFeature } from "../../../features/connections/referrer";
import { SubscriptionsCheckFeature } from "../../../features/subscriptions/check";
import { DefaultLogoComponent } from "../../../shared/components/logos/logos";
import { SideMenuTrigger } from "../libs/menus/sidemenu";

export const NavigationSidebarWidget = (
  { opened, toggle }: disclosureProps,
) => (
  <> 
    <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm" />
    <Stack h={"100vh"} p="md" pt={0}>
      <DefaultLogoComponent />
      <Space />
      <SideMenuTrigger />
      <Space flex={1} />
      <Card radius="md" bg="gray.1">
        <Stack gap="md" p="md">
          <ConnectionsReferrerIPFeature />
          <SubscriptionsCheckFeature />
          <ApplicationsSandboxFeature />
        </Stack>
      </Card>
    </Stack>
  </>
);
