import { GlobalStateContext } from "piral-core";
import { ReactNode } from "react";

declare module "piral-core/lib/types/custom" {
  interface PiralCustomActions {
    setPageMeta: (title?: string, subtitle?: ReactNode, right?: ReactNode) => void;
  }

  interface PiralCustomState {
    page_meta: {
      subtitle?: ReactNode;
      right?: ReactNode;
      title?: string;
    };
  }
}

export const setPageMeta = (
  ctx: GlobalStateContext,
  title?: string,
  subtitle?: ReactNode,
  right?: ReactNode
) => {
  ctx.dispatch((state) => ({
    ...state,
    page_meta: { ...{ subtitle, right, title } },
  }));
};
