// import { client } from "../auth.config";

// client
//   .handleAuthentication()
//   .then(async ({ shouldRender }) => {
//     if (shouldRender) {
//       const app = await import("./app");
//       //readTOken
//       //Fetch User Profile
//       const applications = ["retail-engine"];
//       const activeapp = "retail-engine";
//       app.render({ applications, activeapp });
//     }
//   })
//   .catch(async (err) => {
//     console.error(err);
//     client.logout();
//   });

  import * as app from './app'
  const applications = ["retail-engine"];
  const activeapp = "retail-engine";
  app.render({ applications, activeapp });