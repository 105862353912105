import { AppShell } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import {
  NavigationHeaderWidget,
  NavigationSidebarWidget,
} from "../../../widgets/navigation";
import "@mantine/core/styles.css"
import "@mantine/spotlight/styles.css";
import "../styles/_global.css";

import { PortalDrawer } from "../../../shared/components/drawer";
import { Title as PageTitle } from "backoffice-console-library";
import { useGlobalState } from "piral-core";

export function PortalLayout({ children }: { children: React.ReactNode }) {
  const [opened, { toggle }] = useDisclosure(false);
  const PageTitleProps = useGlobalState((m) => m.page_meta);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: "md", collapsed: { mobile: !opened } }}
      padding="md"
      layout="alt"
    >
      <AppShell.Header>
        <NavigationHeaderWidget {...{ opened, toggle }} />
      </AppShell.Header>
      <AppShell.Navbar>
        <NavigationSidebarWidget {...{ opened, toggle }} />
      </AppShell.Navbar>
      <AppShell.Main
        bg="gray.1"
        mih={"100dvh"}
        display="flex"
        style={{ flexDirection: "column" }}
      >
          <PageTitle {...PageTitleProps} />
          {children}
      </AppShell.Main>
      <PortalDrawer />
    </AppShell>
  );
}
