import { Group, Text } from "@mantine/core";
import { NAVIGATION_CONSTANT } from "../../../../constants";
import React from "react";

export const ConnectionsReferrerIPFeature = () => {
    return <>
    <Group justify="space-between">
        <Text size="sm" fw={300}>{NAVIGATION_CONSTANT.SIDENAV.IP}</Text>
        <Text size="sm" fw={500}>127.0.0.1</Text>
    </Group>
    </>;
}
