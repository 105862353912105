import { GlobalStateContext } from "piral-core";

declare module "piral-core/lib/types/custom" {
  interface PiralCustomActions {
    setActiveApp: (MenuApplication: string) => void;
    setContextApps: (ContextApplications: string[]) => void;
  }

  interface PiralCustomState {
    activeapp: string;
    applications: string[];
  }
}

export const setActiveApp = (
  ctx: GlobalStateContext,
  ActiveApplication: string
) => {
  ctx.dispatch((state) => ({
    ...state,
    activeapp: ActiveApplication,
  }));
};

export const setContextApps = (
  ctx: GlobalStateContext,
  ContextApplications: string[]
) => {
  ctx.dispatch((state) => ({
    ...state,
    applications: ContextApplications,
  }));
};
