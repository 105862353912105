import { TextInput, Kbd, Space, Text, Group, ActionIcon } from "@mantine/core";
import { spotlight, Spotlight, SpotlightActionData } from "@mantine/spotlight";
import { SearchNormal1 } from "iconsax-react";
import React, { useState } from "react";

export const SpotlightSearchComponent = ({actions}) => {
  const [value, setValue] = useState("");

  return (
    <>
      <TextInput
        visibleFrom="xl"
        value={value}
        onChange={spotlight.open}
        onClick={spotlight.open}
        placeholder="Search"
        size="sm"
        radius="md"
        leftSection={<SearchNormal1 size={16} />}
        rightSection={
          <Group gap={0} p={0} ml={-20}>
            <Kbd size="xs">⌘</Kbd>
            <Text size="xs">+</Text>
            <Kbd size="xs">k</Kbd>
          </Group>
        }
      />
      <ActionIcon variant="subtle" size="sm" w={40} h={40} onClick={spotlight.open} hiddenFrom="xl">
        <SearchNormal1 className="ics-path-override" />
      </ActionIcon>
      <Spotlight
        actions={actions}
        nothingFound="Nothing found..."
        highlightQuery
        searchProps={{
          leftSection: <SearchNormal1 />,
          placeholder: "Search...",
        }}
      />
    </>
  );
};

/*TODO:
 * spotlight launches with 6 menu options
 * Clients (#)
 * Documents (?)
 * Savings (>)
 * Loans (<)
 * Messages (&)
 * People (@)
 * nothingFound would have three optons (0- nothing found, 1- searching, 2-Invalid search)
 * Each action will be clickable - onclick will set prefix
 * debounce hook will handled controlled search
 * results will be filtered serverside
 */