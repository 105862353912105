//INFO: Coming from ADIBA
export const PersonalData = [{
    label: "First Name:",
    value: "Oluseyi"
},
{
    label: "Last Name:",
    value: "Akamo"
},
{
    label: "Email Address:",
    value: "seyiox@gmail.com"
},
{
    label: "Phone Number:",
    value: "+234 802 227 9770"
}]

//Should be coming from State
export const ProfileData = {
    avatar: "",
    fullname: "Oluseyi Akamo",
    role: "Administrator",
    username: "seyiakamo"
}