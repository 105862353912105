import { Button, Divider, Group, Popover, Stack, Text } from "@mantine/core";
import { NAVIGATION_CONSTANT } from "../../../../constants";
import React from "react";
import { NotificationLoadedState } from "../../../entities/notification/ui/preview";

export const NotificationsPreviewFeature = ( NotificationsPreviewData : INotificationsItem[] ) => {
  return (
    <>
      <Popover.Dropdown p={5}>
        <Stack gap={5} p={0}>
          <NotificationLoadedState {...NotificationsPreviewData} />
          <Divider />
          <Group grow justify="center">
            <Button variant="subtle">
              <Text size="xs" fw={300}>
                {NAVIGATION_CONSTANT.MORE_TEXT.NOTIFICATIONS} &raquo;
              </Text>
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </>
  );
};
