import * as React from 'react';

import { ErrorComponentsState } from "piral";
import { Link } from 'react-router-dom';

export const errors: Partial<ErrorComponentsState> = {
    not_found: () => (
      <div>
        <p className="error">Could not find the requested page. Are you sure it exists?</p>
        <p>
          Go back <Link to="/">to the dashboard</Link>.
        </p>
      </div>
    ),
  };