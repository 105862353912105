import { Anchor, Badge, Stack, Table, Title } from "@mantine/core";
import React from "react";

export const SubscriptionsSettingsBillingHistoryFeature = () => {

  const Bills = [
    {
        date: "01/01/2024",
        details: "Standard Plan (Monthly)",
        amount: "$2,500",
        status: "Paid",
      download: "Invoice 01 January 2024.pdf"
    },
    {
        date: "01/01/2024",
        details: "Standard Plan (Monthly)",
        amount: "$2,500",
        status: "Paid",
      download: "Invoice 01 January 2024.pdf"
    },
    {
        date: "01/01/2024",
        details: "Standard Plan (Monthly)",
        amount: "$2,500",
        status: "Paid",
      download: "Invoice 01 January 2024.pdf"
    },
    {
        date: "01/01/2024",
        details: "Standard Plan (Monthly)",
        amount: "$2,500",
        status: "Paid",
      download: "Invoice 01 January 2024.pdf"
    },
    {
        date: "01/01/2024",
        details: "Standard Plan (Monthly)",
        amount: "$2,500",
        status: "Paid",
      download: "Invoice 01 January 2024.pdf"
    },
    {
        date: "01/01/2024",
        details: "Standard Plan (Monthly)",
        amount: "$2,500",
        status: "Paid",
      download: "Invoice 01 January 2024.pdf"
    },
  ];

  const rows = Bills.map((row,index) => (
    <Table.Tr key={index} c={'adiba'} fw={300} fz={'sm'} h={50}>
      <Table.Td w={50}>{index+1}</Table.Td>
      <Table.Td>{row.date}</Table.Td>
      <Table.Td>{row.details}</Table.Td>
      <Table.Td>{row.amount}</Table.Td>
      <Table.Td><Badge size="sm" color="green">{row.status}</Badge></Table.Td>
      <Table.Td align="center"><Anchor fw={300} fz={'sm'} c={'adiba'} href={row.download}>{row.download}</Anchor></Table.Td>
    </Table.Tr>
  ));

  return (
    <Stack gap={'sm'}>
      <Title fz={"sm"} fw={500} pt={"md"} c={"dimmed"}>
      Billing History
      </Title>
        <Table miw={700}>
          <Table.Thead>
            <Table.Tr tt={'uppercase'}>
            <Table.Th fw={500}  fz={'xs'}>#</Table.Th>
            <Table.Th fw={500}  fz={'xs'}>Date</Table.Th>
            <Table.Th fw={500}  fz={'xs'}>Details</Table.Th>
              <Table.Th fw={500}  fz={'xs'}>Amount</Table.Th>
              <Table.Th fw={500}  fz={'xs'}>Status</Table.Th>
              <Table.Th ta={'center'} fw={500} fz={'xs'}>Download</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
    </Stack>
  );
};
