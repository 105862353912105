import { Drawer, getThemeColor, useMantineTheme } from "@mantine/core";
import { Back } from "iconsax-react";
import { useGlobalStateContext } from "piral-core";
import React from "react";

export const PortalDrawer = () => {
    const context = useGlobalStateContext();
    const theme = useMantineTheme()
    return <>
    <Drawer
      title={context.state((m) => m.drawer.title)}
      opened={context.state((m) => m.drawer.open)}
      onClose={() => context.setDrawer()}
      position='right'
      size={'lg'}
      radius={'md'}
       offset={10}
       overlayProps= {{color: getThemeColor('adiba.3', theme)}}
       closeButtonProps={{icon: <Back/>}}
       padding={'lg'}
       transitionProps={{duration: 500, transition: 'slide-left'}}
    >
        {context.state((m) => m.drawer.page)}
    </Drawer>
    </>
}
