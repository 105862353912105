import { ScrollArea, Stack, Timeline, Box, Group, Text } from "@mantine/core";
import { IconBell } from "@tabler/icons-react";
import React from "react";
import Moment from "react-moment";

export const NotificationPreviewShell = ({ children, PreviewState }) => (
  <>
    <ScrollArea offsetScrollbars scrollbars="y">
      <Stack h={350} w={300} align="self-start" px={10}>
        {["loading", "loaded"].includes(PreviewState) ? (
          <Timeline lineWidth={1} active={-1} p="sm">
            {children}
          </Timeline>
        ) : (
            <Box>
              {children}
            </Box>
        )}
      </Stack>
    </ScrollArea>
  </>
);

export const NotificationPreviewItemShell = ({title, message, icon, timestamp}: INotificationsItem) => {
    // TODO: get icon from parameter with Alarm as the default. 
    return <Timeline.Item bullet={<IconBell size={12} stroke={1}/>}>
        <Stack gap={0}>
            <Text fw={300} size="xs" w={280}>
                {title}
            </Text>
            <Text fz={12} fw={300} c="dimmed">
                {message}
            </Text>
            <Text  fw={300} fs="italic" fz={9} c="altiba.3" >
                <Moment fromNow >{timestamp}</Moment>
            </Text>
        </Stack>
    </Timeline.Item>
};