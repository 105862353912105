import { PiralActionsApi, PiralPlugin, useAction } from "piral";
import { ReactNode } from "react";

declare module "piral-core/lib/types/custom" {
  interface PiletCustomApi extends PiralActionsApi {}

  interface PiralActionsApi {
    registerPiralAction : (name: string, metadata: {title?: string, subtitle?: ReactNode, right?: ReactNode, page?: React.JSX.Element}) => void;
  }
}

export const registerPiralActionApi = (): PiralPlugin<PiralActionsApi> => {
  return (context) => (api, target) => ({
    registerPiralAction(name, metadata) {
      if (name == 'setPageMeta'){
        context.setPageMeta(metadata?.title, metadata?.subtitle, metadata?.right)
      }
      if (name = 'setDrawer') {
        context.setDrawer(metadata?.page, metadata?.title)
      }
    },
  });
};
