//TODO: Coming from either CONSOLE or IAM
export const LocaleData = [{
    label: "Language:",
    value: "English (EN)"
},
{
    label: "Timezone:",
    value: "Africa/ Lagos (GMT +1)"
},
{
    label: "Currency:",
    value: "Nigerian Naira (NGN)"
},
{
    label: "Numbering:",
    value: "###,###.00"
}]