import React from "react";

export const LedgerSyncDashboardHomePage = ({ children }) => {
  return (
    <>
      <>LedgerSyncDashboardHomePage</>
      {children}
    </>
  );
};
