import { Group, Switch, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NAVIGATION_CONSTANT } from "../../../../constants";
import React from "react";

export const ApplicationsSandboxFeature = () => {
  const [opened, { toggle }] = useDisclosure(false);
  return (
    <>
      <Group justify="space-between">
        <Text size="sm" fw={300}>
          {opened
            ? NAVIGATION_CONSTANT.SIDENAV.PRODUCTION
            : NAVIGATION_CONSTANT.SIDENAV.SANDBOX}
        </Text>
        <Switch defaultChecked={opened} onClick={toggle} />
      </Group>
    </>
  );
};
