import {
  Card,
  SimpleGrid,
  ScrollArea,
  Title,
  Group,
  Text,
  Box,
  Button,
  Stack,
  Indicator,
  Divider,
  Checkbox,
  Switch,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import React from "react";
import "./styles.css";

export const SubscriptionsSettingsPlanFeature = () => {
  const Plans = [
    {
      title: "Standard",
      duration: "4 days",
      price: "$2,500", //price should read from local the active currency adn
      isActive: true,
    },
    {
      title: "Premium",
      duration: "30 days",
      price: "$4,000", //price should read from local the active currency adn
      isActive: false,
    },
    {
      title: "Enterprise",
      duration: "30 days",
      price: "$9,999", //price should read from local the active currency adn
      isActive: false,
    },
  ];

  const PlanItems = Plans.map((item, index) => {
    return (
      <Indicator
        size={20}
        label={<IconCheck size={12} strokeWidth={2} />}
        disabled={!item.isActive}
        key={index}
      >
        <Card
          bg={item.isActive ? "gray.1" : "white"}
          p={"md"}
          radius={"sm"}
          withBorder
        >
          <Stack gap={"xl"}>
            <Group justify="space-between" align="flex-start">
              <Box>
                <Title order={6} fw={500}>
                  {item.title}
                </Title>
                <Text fz={"xs"} c={"altiba.3"} fw={300}>
                  {item.duration} {item.isActive ? " remaining" : ""}
                </Text>
              </Box>
              <Text fz={"sm"} fw={500}>
                {item.price}
                <Text fz={"sm"} component="span" c={"dimmed"}>
                  {" /month"}
                </Text>
              </Text>
            </Group>
            <Button
              w={100}
              color="adiba"
              size="xs"
              fw={500}
              variant={item.isActive ? "outline" : "filled"}
            >
              Pay Early
            </Button>
          </Stack>
        </Card>
      </Indicator>
    );
  });
  return (
    <Stack gap={'sm'}>
      <Title fz={"sm"} fw={500} pt={"md"} c={"dimmed"}>
        Plans
      </Title>
      <ScrollArea scrollbars="x">
        <SimpleGrid cols={3} w={1024} pt={"sm"} spacing={"md"}>
          {PlanItems}
        </SimpleGrid>
      </ScrollArea>
      <AutoRenewFeature />
    </Stack>
  );
};

const AutoRenewFeature = () => {
  return (
    <>
      <Switch
        fw={400}
        maw={1024}
        label="Enable auto renew?"
        labelPosition="left"
        w={"100%"}
        onLabel="ON"
        offLabel="OFF"
        className="subscription-renew-switch"
      />
    </>
  );
};
