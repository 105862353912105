import { NavLink, Stack } from "@mantine/core";
import React from "react";
import { Home, Setting5 } from "iconsax-react";
import { Menu, useGlobalState } from "piral";
import { SideMenuIcons } from "../../../../shared/library/dictionaries/icons";
import { RedirectLink } from "../../../../shared/library/navigate/link";
import { isActiveLink } from "../../../..//shared/library/navigate/active";

export const SideMenuTrigger = () => {
  const sidemenu = useGlobalState((m) => {
    if(m.activeapp)
        return m.menus[m.activeapp]
    return []
  }) 
  
  const active = false;
  const color = "adiba";
  const variant = "filled";

  return (
    <Stack gap="sm">
      <NavLink
        style={{ borderRadius: 40 }}
        fw={300}
        active = {isActiveLink(/^\/$/)}
        label="Dashboard"
        {...{ color, variant }}
        leftSection={<Home size={16} className="ics-path-override" />}
        onClick={RedirectLink('/')}
      />
      <DynamicSideMenu {...{ active, color, variant, sidemenu }} />
      <Menu type="admin" />
      <NavLink
        style={{ borderRadius: 40 }}
        onClick={RedirectLink('/settings/preferences')}
        fw={300}
        label="Settings"
        active = {isActiveLink(/^\/settings/)}
        {...{ color, variant }}
        leftSection={<Setting5 size={16} className="ics-path-override" />}
      />
    </Stack>
  );
};

const DynamicSideMenu = ({ active, color, variant, sidemenu }) => {
  const variant_child = "outline";
  return (
    <>
      {sidemenu?.map((SideMenuItem) => {
        return (
          <NavLink
            style={{ borderRadius: 40 }}
            key={`sidemenu-${SideMenuItem.id}`}
            fw={300}
            label={SideMenuItem.label}
            {...{ active, color, variant }}
            leftSection={SideMenuIcons[SideMenuItem.icon]}
          >
            {SideMenuItem?.children?.length
              ? SideMenuItem?.children.map((item) => {
                  return (
                    <NavLink
                      key={`sidemenu-${SideMenuItem.id}`}
                      label={item.label}
                      fw={300}
                      {...{ active, color, variant_child }}
                    />
                  );
                })
              : undefined}
          </NavLink>
        );
      })}
    </>
  );
};
