import React from "react";

export const PaymentEngineDashboardHomePage = ({ children }) => {
  return (
    <>
      <>PaymentEngineDashboardHomePage</>
      {children}
    </>
  );
};
