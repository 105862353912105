import { Group, Burger, Divider } from "@mantine/core";
import { AppSwitcher } from "../libs/switcher";
import React, { act } from "react";
import { Menu } from "piral";

import { SettingsMenuTrigger , UserMenuTrigger, NotificationMenuTrigger} from "../libs/menus";
import { SpotlightSearchComponent } from "../../../shared/components/search";
import { SpotlightActions as actions } from "../model/search"

export const NavigationHeaderWidget = ({ opened, toggle }: disclosureProps) => (
  <Group id="NavigationHeader" w="100%">
    <Burger
      opened={opened}
      onClick={toggle}
      hiddenFrom="md"
      size="sm"
      pl="md"
      pr={0}
    />
    <Group justify="space-between" flex={1}>
      <AppSwitcher />
      <Group gap={10} h={60} px={10} visibleFrom="md">
        <SpotlightSearchComponent {...{actions}}/>
        <Menu type="header" />
        <Divider orientation="vertical" />
        <NotificationMenuTrigger/>
        <Divider orientation="vertical" />
        <SettingsMenuTrigger />
        <Divider orientation="vertical" />
        <UserMenuTrigger />
      </Group>
    </Group>
    <Group justify="flex-end" hiddenFrom="md" px={10} flex={1}>
      <UserMenuTrigger />
    </Group>
  </Group>
);
