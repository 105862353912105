import { InputWithButton } from "../../../shared/components/inputs/inputs";
import {
  FeaturesCard,
} from "../../../entities/applications/ui/apptile";
import { SimpleGrid, Stack } from "@mantine/core";
import React from "react";

export const ApplicationsSettingsWidget = () => {
  const applications = Array(8).fill({
    title: "Lorem",
    imageLink: "1",
    description: "kjsjdsds",
    subscription_fee: "20",
  });

  return (
    <Stack p={"md"}>
      <InputWithButton
        size="lg"
        mt={"md"}
        placeholder="Search application by title"
        radius={"xl"}
      />
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 3, xl: 4 }}
        pt={"md"}
        spacing={{ base: "sm", xl: "xl" }}
        verticalSpacing={{ base: "sm", xl: "xl" }}
      >
        {applications.map((item, index) => {
          return <FeaturesCard key={index} />;
        })}
      </SimpleGrid>
    </Stack>
  );
};
