import React from "react";
import {
  NotificationPreviewItemShell,
  NotificationPreviewShell,
} from "./shell";

export const NotificationLoadedState = (PreviewItemData: INotificationsItem[]) => {
  return (
    <>
      <NotificationPreviewShell PreviewState={'loaded'}>
        {Object.keys(PreviewItemData).map((prev, index) => (
          <NotificationPreviewItemShell key={`notification-preview-item-${index}`} {... PreviewItemData[prev]}/>
        ))}
      </NotificationPreviewShell>
    </>
  );
};
