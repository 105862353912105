import { setupOidcClient } from "piral-oidc";

const auth = {
    "clientID": "sG364TLm5fva2DXx9yr09qrey8Ma",
    "clientSecret":"qxN3oGcX0ULeOYzktTsildfq6rA_AUNBUEPkNq27raUa",
    "baseUrl": "https://accounts.adiba.app/oauth2/token",
}

export const client = setupOidcClient({
    clientId: auth.clientID,
    responseType: "code",
    clientSecret: auth.clientSecret,
    identityProviderUri: auth.baseUrl,
});