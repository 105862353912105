import { ActionIcon, Text, Menu as MantineMenu, Badge } from "@mantine/core";
import { NAVIGATION_CONSTANT } from "../../../../../constants";
import { ArrowRight2, Box2, CloudConnection, Glass, Setting2, Setting5, Warning2 } from "iconsax-react";
import { Menu } from "piral";
import React from "react";
import { Link } from "react-router-dom";

export const SettingsMenuTrigger = () => {
  const SettingMenuSize = 40;
  const ApplicationCount = 4;

  return (
    <MantineMenu
      width={250}
      transitionProps={{ transition: "pop" }}
      position="bottom"
      withArrow
      withinPortal
      offset={10}
      arrowOffset={20}
      arrowSize={10}
    >
      <MantineMenu.Target>
        <ActionIcon variant="subtle" h={40} w={40}>
          <Setting2 className="ics-path-override" />
        </ActionIcon>
      </MantineMenu.Target>
      <MantineMenu.Dropdown>
        {/* EDIT PREFERENCES */}
        <MantineMenu.Item
          h={SettingMenuSize}
          leftSection={<Setting5 size={16} className="ics-path-override" />}
          component={Link}
          to= {"/settings/preferences"}
          onClick={void 0}
        >
          <Text size="xs" fw={300}>
            {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.preferences}
          </Text>
        </MantineMenu.Item>
        {/* EDIT CONNECTIONS */}
        <MantineMenu.Item
          h={SettingMenuSize}
          leftSection={<CloudConnection size={16} className="ics-path-override" />}
          component={Link}
          to= {"/settings/connections"}
          onClick={void 0}
        >
          <Text size="xs" fw={300}>
            {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.connections}
          </Text>
        </MantineMenu.Item>
        
        {/* MY APPLICATIONS */}
        <MantineMenu.Item
          h={SettingMenuSize}
          leftSection={<Box2 size={16} className="ics-path-override" />}
          rightSection={<Badge size="sm" color={'altiba.3'}>{ApplicationCount}</Badge>}
          component={Link}
          to= {"/settings/applications?q=myapplications"}
          onClick={void 0}
        >
          <Text size="xs" fw={300}>
            {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.applications}
          </Text>
        </MantineMenu.Item>
        <Menu type="general" />
        {/* MY APPLICATIONS */}
        <MantineMenu.Divider />
        <MantineMenu.Label pt={"xs"}>
          <Text size="xs">
            {NAVIGATION_CONSTANT.MENU.SETTINGS.SECTIONS.support}
          </Text>
        </MantineMenu.Label>
        <MantineMenu.Item
          h={SettingMenuSize}
          leftSection={<Glass size={16} className="ics-path-override" />}
          rightSection={<Badge size="sm" color={'adiba.3'}>{ApplicationCount}</Badge>}
          onClick={void 0}
        >
          <Text size="xs" fw={300}>
            {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.opentickets}
          </Text>
        </MantineMenu.Item>
        {/* RAISE TICKETS */}
        <MantineMenu.Item
          h={SettingMenuSize}
          leftSection={<Warning2 size={16} className="ics-path-override" />}
          onClick={void 0}
        >
          <Text size="xs" fw={300}>
            {NAVIGATION_CONSTANT.MENU.SETTINGS.ITEMS.createticket}
          </Text>
        </MantineMenu.Item>
      </MantineMenu.Dropdown>
    </MantineMenu>
  );
};