import {
  GlobalStateContext,
  PiletRegisterMenuItemApi,
  PiralPlugin,
} from "piral-core";

declare module "piral-core/lib/types/custom" {
  interface PiralCustomActions {
    setMenu: (MenuApplication: string, MenuItems: INavigationItem[]) => void;
    setActiveMenu: (ActiveMenuItem: string) => void;
  }

  interface PiralCustomState {
    menus: {
      [key in IApplications]: INavigationItem[];
    };
    activemenu: string;
  }

  interface PiletCustomApi extends PiletRegisterMenuItemApi {}

  interface PiletRegisterMenuItemApi {
    registerMenuItem: (
      MenuApplication: string,
      MenuItem: INavigationItem
    ) => void;
  }
}

export const setActiveMenu = (
  ctx: GlobalStateContext,
  ActiveMenuItem: string
) => {
  ctx.dispatch((state) => ({
    ...state,
    activemenu: ActiveMenuItem,
  }));
};

export const setMenu = (
  ctx: GlobalStateContext,
  MenuApplication: string,
  MenuItems: INavigationItem[]
) => {
  ctx.dispatch((state) => ({
    ...state,
    menus: {
      ...state.menus,
      [MenuApplication]: MenuItems,
    },
  }));
};

export const registerMenuItemApi =
  (): PiralPlugin<PiletRegisterMenuItemApi> => {
    return (context) => (api, target) => ({
      registerMenuItem(MenuApplicationPath, MenuItem) {
        const MenuApplicationArray = MenuApplicationPath.split("#");
        const MenuApplication = MenuApplicationArray[0];
        if (MenuApplicationArray.length > 1) {
          const ParentMenuApplication = MenuApplicationArray[1];
          context.dispatch((state) => {
            const StateMenu: INavigationItem[] = state.menus[MenuApplication];
            const ParentMenuApplicationIndex: number = StateMenu.findIndex(
              (item) => item.id === ParentMenuApplication
            );
            if (ParentMenuApplicationIndex === -1) return state;
            let OldChild: INavigationItem[] =
              StateMenu[ParentMenuApplicationIndex]?.children;
            OldChild = [...OldChild, MenuItem];
            OldChild.sort((a, b) => (a.seq > b.seq ? 1 : -1));
            const updatedV1 = [
              ...state.menus[MenuApplication].slice(
                0,
                ParentMenuApplicationIndex
              ),
              {
                ...state.menus[MenuApplication][ParentMenuApplicationIndex],
                children: OldChild,
              },
              ...state.menus[MenuApplication].slice(
                ParentMenuApplicationIndex + 1
              ),
            ];
            return {
              ...state,
              menus: { ...state.menus, [MenuApplication]: updatedV1 },
            };
          });
        } else {
          context.dispatch((state) => ({
            ...state,
            menus: {
              ...state.menus,
              [MenuApplication]: [...state.menus[MenuApplication], MenuItem],
            },
          }));
        }
      },
    });
  };
