import { Button, Group, Stack, Text } from "@mantine/core";
import React from "react";
import { useAction, useGlobalState } from "piral-core";
import { ApplicationIcons } from "../../../shared/library/dictionaries/icons";

export const ApplicationSwitcherItemUIShell = ({
  id,
  title,
  description,
  icon,
}: IApplicationItem) => {
  const application = useGlobalState(m => m.activeapp)
  const setActiveApp = useAction('setActiveApp')
  const itemIsActive = (id === application);
  return (
    <>
      <Button
        variant="subtle"
        fw={300}
        mih={70}
        bg={itemIsActive ? "gray.1" : {}}
        c={itemIsActive ? "altiba" : "adiba"}
        onClick={()=>{
          setActiveApp(id)
        }}
      >
        <Group w={320}>
          {ApplicationIcons[icon]}
          <Stack align="flex-start" gap={5} >
            <Text size="sm" fw={300}>
              {title}
            </Text>
            <Text size="xs" fz={11} fw={300}>
              {description}
            </Text>
          </Stack>
        </Group>
      </Button>
    </>
  );
};
